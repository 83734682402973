


















































































































.generate-signup-link {
  .el-dialog__header {
    padding: 0 !important;
  }
  .link-preview {
    line-height: 1.5;
    font-size: 12px;
    color: #000;
  }
}
