



























































































































.items {
  .item {
    border-bottom: 1px solid #e5e5e5;

    &-property {
      font-family: "Raleway Regular", serif;
      font-size: 16px;
      padding-left: 24px;
    }

    &-value {
      font-family: "Raleway Bold", serif;
      font-size: 16px;
      padding-right: 24px;
    }
  }
}

.buttons {
  text-align: center;

  button {
    width: 164px;
    height: 54px;
    border-radius: 15px;
    font-family: "Raleway Medium", serif;
    font-size: 18px;

    &.delete-button {
      border: 2px solid #FF8A00;
      color: #FF8A00;
    }
  }
}

.delete-organization-dialog::v-deep {
  .el-dialog__body {
    white-space: normal;
  }
}
